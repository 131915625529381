import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

const ProgressBar = props => {

  const currentSectionName = props?.currentSectionName;
  // const sectionNames = ["CURIOSITY", "CREATIVITY", "INITIATIVE", "RISK TAKING", "COLLABORATION", "FLEXIBILITY"];

  let activeColor = '#01B1FD';
  let passiveColor = '#343333';

  let section1style = { color: activeColor, fontWeight: '700' };
  let section2style = { color: passiveColor, fontWeight: '400' };
  let section3style = { color: passiveColor, fontWeight: '400' };
  let section4style = { color: passiveColor, fontWeight: '400' };
  let section5style = { color: passiveColor, fontWeight: '400' };
  let section6style = { color: passiveColor, fontWeight: '400' };
  let mobileSectionStyle = { color: activeColor, fontWeight: '700' };

  const [section1, setSection1] = React.useState({ color: activeColor, fontWeight: '700' });
  const [section2, setSection2] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section3, setSection3] = React.useState({ color: passiveColor, fontWeight: '400' });
  const [section4, setSection4] = React.useState({ color: passiveColor, fontWeight: '400' });

  let progressWidth = '25%';
  if (window.innerWidth < 650) {
    progressWidth = '50%';
  }

  let progress1 = 0;
  let progress2 = 0;
  let progress3 = 0;
  let progress4 = 0;
  let progress5 = 0;
  let progress6 = 0;
  let mobileProgress = 0;

  if (props.currentProgress < 5) {
    progress1 = ((props.currentProgress + 1) / 5) * 100;
    mobileProgress = progress1;
    section1style = { color: activeColor, fontWeight: '700' };
    section2style = { color: passiveColor, fontWeight: '400' };
    section3style = { color: passiveColor, fontWeight: '400' };
    section4style = { color: passiveColor, fontWeight: '400' };
    section5style = { color: passiveColor, fontWeight: '400' };
    section6style = { color: passiveColor, fontWeight: '400' };
  } else {
    progress1 = 100;
    section1style = { color: passiveColor, fontWeight: '400' };
  }

  if (props.currentProgress >= 5 && props.currentProgress < 10) {
    progress2 = (((props.currentProgress + 1) - 5) / 5) * 100;
    mobileProgress = progress2;
    section1style = { color: passiveColor, fontWeight: '400' };
    section2style = { color: activeColor, fontWeight: '700' };
    section3style = { color: passiveColor, fontWeight: '400' };
    section4style = { color: passiveColor, fontWeight: '400' };
  } else {
    if (props.currentProgress < 9) {
      progress2 = 0;
    } else {
      progress2 = 100;
    }
    section2style = { color: passiveColor };
  }

  if (props.currentProgress >= 10 && props.currentProgress < 15) {
    progress3 = (((props.currentProgress + 1) - 10) / 5) * 100;
    mobileProgress = progress3;
    section1style = { color: passiveColor, fontWeight: '400' };
    section2style = { color: passiveColor, fontWeight: '400' };
    section3style = { color: activeColor, fontWeight: '700' };
    section4style = { color: passiveColor, fontWeight: '400' };
  } else {
    if (props.currentProgress < 14) {
      progress3 = 0;
    } else {
      progress3 = 100;
    }
    section3style = { color: passiveColor, fontWeight: '400' };
  }

  if (props.currentProgress >= 15 && props.currentProgress < 20) {
    progress4 = (((props.currentProgress + 1) - 15) / 5) * 100;
    mobileProgress = progress4;
    section1style = { color: passiveColor, fontWeight: '400' };
    section2style = { color: passiveColor, fontWeight: '400' };
    section3style = { color: passiveColor, fontWeight: '400' };
    section4style = { color: activeColor, fontWeight: '700' };
  } else {
    if (props.currentProgress < 19) {
      progress4 = 0;
    } else {
      progress4 = 100;
    }
    section4style = { color: passiveColor };
  }

  if (props.currentProgress >= 20 && props.currentProgress < 25) {
    progress5 = (((props.currentProgress + 1) - 20) / 5) * 100;
    mobileProgress = progress5;
    section1style = { color: passiveColor, fontWeight: '400' };
    section2style = { color: passiveColor, fontWeight: '400' };
    section3style = { color: passiveColor, fontWeight: '400' };
    section4style = { color: passiveColor, fontWeight: '400' };
    section5style = { color: activeColor, fontWeight: '700' };
    section6style = { color: passiveColor, fontWeight: '400' };
  } else {
    if (props.currentProgress < 24) {
      progress5 = 0;
    } else {
      progress5 = 100;
    }
    section5style = { color: passiveColor };
  }

  if (props.currentProgress >= 25 && props.currentProgress < 30) {
    progress6 = (((props.currentProgress + 1) - 25) / 5) * 100;
    mobileProgress = progress6;
    section1style = { color: passiveColor, fontWeight: '400' };
    section2style = { color: passiveColor, fontWeight: '400' };
    section3style = { color: passiveColor, fontWeight: '400' };
    section4style = { color: passiveColor, fontWeight: '400' };
    section5style = { color: passiveColor, fontWeight: '400' };
    section6style = { color: activeColor, fontWeight: '700' };
  } else {
    if (props.currentProgress < 29) {
      progress6 = 0;
    } else {
      progress6 = 100;
    }
    section6style = { color: passiveColor };
  }

  console.log("currentProgress", props.currentProgress);
  console.log("Progress Width", progressWidth);
  let progressPercentage = Math.round(((props?.currentProgress + 1) / 30) * 100);

  if (window.innerWidth < 650) {

    return (
      <>
        <div className="progress-bar">
          <div className="bar-section" style={{ width: progressWidth }}>
            <p className="progress-percent-text" variant="determinate" style={{
              marginLeft: `calc(${progressPercentage}% - 8px)`
            }}>{`${progressPercentage}%`}</p>
            <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progressPercentage} /></div>
            <div className="section-title" style={mobileSectionStyle}>{currentSectionName}</div>
          </div>
        </div>
      </>
    );
  } else return (
    <div>
      <p className="progress-percent-text" variant="determinate" style={{
        marginLeft: `calc(${progressPercentage}% - 12px)`
      }}>{`${progressPercentage}%`}</p>
      <div className="progress-bar">
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress1} /></div>
          <div className="section-title" style={section1style}>CURIOSITY</div>
        </div>
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress2} /></div>
          <div className="section-title" style={section2style}>INITIATIVE</div>
        </div>
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress3} /></div>
          <div className="section-title" style={section3style}>RISK-TAKING</div>
        </div>
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress4} /></div>
          <div className="section-title" style={section4style}>CREATIVITY</div>
        </div>
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress5} /></div>
          <div className="section-title" style={section5style}>FLEXIBILITY</div>
        </div>
        <div className="bar-section" style={{ width: progressWidth }}>
          <div className="bar"><LinearProgress className="custom-bar" variant="determinate" value={progress6} /></div>
          <div className="section-title" style={section6style}>COLLABORATION</div>
        </div>
      </div>

    </div>
  );
}
export default ProgressBar;