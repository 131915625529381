import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';


import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     "& .MuiFilledInput-root.Mui-focused": {
//       border: "10px solid gray"
//     }
//   }
// }));

const jobTitleValues = [
  { "value": "Other", "label" :"Other" },
  { "value": "AV Director", "label" :"AV Director" },
  { "value": "Administrative Assistant", "label" :"Administrative Assistant" },
  { "value": "Administrative Pastor", "label" :"Administrative Pastor" },
  { "value": "Area Director", "label" :"Area Director" },
  { "value": "Bookkeeper", "label" :"Bookkeeper" },
  { "value": "Campus Pastor", "label" :"Campus Pastor" },
  { "value": "Chief Executive Officer (CEO)", "label" :"Chief Executive Officer (CEO)" },
  { "value": "Chief Financial Officer", "label" :"Chief Financial Officer" },
  { "value": "Children's Ministry Coordinator", "label" :"Children's Ministry Coordinator" },
  { "value": "Children's Pastor", "label" :"Children's Pastor" },
  { "value": "College Pastor", "label" :"College Pastor" },
  { "value": "Communications Coordinator", "label" :"Communications Coordinator" },
  { "value": "Communications Director", "label" :"Communications Director" },
  { "value": "Communications Project Manager", "label" :"Communications Project Manager" },
  { "value": "Community Pastor", "label" :"Community Pastor" },
  { "value": "Connections Pastor", "label" :"Connections Pastor" },
  { "value": "Content Writer", "label" :"Content Writer" },
  { "value": "Creative Arts Pastor", "label" :"Creative Arts Pastor" },
  { "value": "Digital Media Analyst", "label" :"Digital Media Analyst" },
  { "value": "Director Member Care", "label" :"Director Member Care" },
  { "value": "Director of Mobilization", "label" :"Director of Mobilization" },
  { "value": "Discipleship Pastor", "label" :"Discipleship Pastor" },
  { "value": "Events Coordinator", "label" :"Events Coordinator" },
  { "value": "Executive Pastor", "label" :"Executive Pastor" },
  { "value": "Executive Vice President", "label" :"Executive Vice President" },
  { "value": "Facility Manager", "label" :"Facility Manager" },
  { "value": "Family Pastor", "label" :"Family Pastor" },
  { "value": "Field Missionary", "label" :"Field Missionary" },
  { "value": "Graphic Designer", "label" :"Graphic Designer" },
  { "value": "Groups Director", "label" :"Groups Director" },
  { "value": "Guest Services Director", "label" :"Guest Services Director" },
  { "value": "High School Pastor", "label" :"High School Pastor" },
  { "value": "Human Resources Coordinator", "label" :"Human Resources Coordinator" },
  { "value": "IT Director", "label" :"IT Director" },
  { "value": "Leadership Development Pastor", "label" :"Leadership Development Pastor" },
  { "value": "Middle School Pastor", "label" :"Middle School Pastor" },
  { "value": "Mission and Outreach Coordinator", "label" :"Mission and Outreach Coordinator" },
  { "value": "Missions Pastor", "label" :"Missions Pastor" },
  { "value": "Office Manager", "label" :"Office Manager" },
  { "value": "Online Community Manager", "label" :"Online Community Manager" },
  { "value": "Outreach Pastor", "label" :"Outreach Pastor" },
  { "value": "Pastoral Care Minister", "label" :"Pastoral Care Minister" },
  { "value": "Personal Assistant to Lead Pastor", "label" :"Personal Assistant to Lead Pastor" },
  { "value": "Preschool Director", "label" :"Preschool Director" },
  { "value": "President", "label" :"President" },
  { "value": "Production Coordinator", "label" :"Production Coordinator" },
  { "value": "Senior Pastor", "label" :"Senior Pastor" },
  { "value": "Small Group Pastor", "label" :"Small Group Pastor" },
  { "value": "Social Media Manager", "label" :"Social Media Manager" },
  { "value": "Stewardship Pastor", "label" :"Stewardship Pastor" },
  { "value": "Student Pastor", "label" :"Student Pastor" },
  { "value": "Teacher Pastor", "label" :"Teacher Pastor" },
  { "value": "Vice President", "label" :"Vice President" },
  { "value": "Vice President of Member Care", "label" :"Vice President of Member Care" },
  { "value": "Vice President of Mobilization", "label" :"Vice President of Mobilization" },
  { "value": "Video Producer", "label" :"Video Producer" },
  { "value": "Volunteer Coordinator", "label" :"Volunteer Coordinator" },
  { "value": "Web Developer", "label" :"Web Developer" },
  { "value": "Worship Leader", "label" :"Worship Leader" },
  { "value": "Worship Pastor", "label" :"Worship Pastor" },
];

const Details = (props) => {

  
  // let styles = useStyles();
  // console.log(styles);

  let navigate                                = useNavigate();
  const { id }                                = useParams();
  const [searchParams, setSearchParams]       = useSearchParams();

  const [isSaving, setIsSaving]               = useState(false);
  const [isLoading, setIsLoading]             = useState(false);
  const [isFormValid, setIsFormValid]         = useState(false);
  const [isMember, setIsMember]               = useState(true);
  const [firstName, setFirstName]             = useState("");
  const [lastName, setLastName]               = useState("");
  const [email, setEmail]                     = useState("");
  const [jobTitle, setJobTitle]               = useState("none");
  const [jobTitleMember, setJobTitleMember]   = useState("");
  const [churchName, setChurchName]           = useState("");
  const [zipCode, setZipCode]                 = useState("");
  const [firstNameError, setFirstNameError]   = useState(false);
  const [lastNameError, setLastNameError]     = useState(false);
  const [emailError, setEmailError]           = useState(false);
  const [jobTitleError, setJobTitleError]     = useState(false);
  const [churchNameError, setChurchNameError] = useState(false);
  const [zipCodeError, setZipCodeError]       = useState(false);

  useEffect(() => {
    if(searchParams.get("member") != null) {
      getUserDetails();
    } else {
      setIsMember(false);
    }
  }, []);

  let focusedElm = document.body.getElementsByClassName("Mui-focused");
  // .querySelector(".Mui-focused");
  // console.log(focusedElm);
  useEffect(() => {
    console.log("focus changed");
  }, [focusedElm]);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;


  const getUserDetails = () => {
    setIsLoading(true);
  
    fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        setIsMember(true);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setJobTitle("none");
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    });
  }

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  // const updateJobTitle = (event) => {
  const updateJobTitle = (selectedOption) => {
    // console.log(event.target.value);
    console.log(selectedOption.value);
    setJobTitle(selectedOption.value);
    checkFormValid();
  }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      console.log(firstName);
      console.log(jobTitle);
      console.log(jobTitleMember);
      if(firstName != "" && lastName != "") {
        console.log("We get here");
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    } else {
      if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    }
  }

  const showErrors = () => {
    if(firstName == "") {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(lastName == "") {
      setLastNameError(true);
    }
    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(jobTitle == "none" || !jobTitle) {
      setJobTitleError(true);
    }
    if(churchName == "") {
      setChurchNameError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + searchParams.get("member");
    }

    let jsonBody        = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "zipCode": zipCode
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        navigate("/results/" + id, { replace: true });
      } else {
        // setOpenSnackbar(true);
      }
    });

  }

  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    if(isMember && firstName != "" && lastName != "" && jobTitle != "none") {
      submitToApi();
    } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{'color': '#fff'}} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }
    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please confirm a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please make a selection.</div>
      }
    }
    if(field == "churchName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your ministry name.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your postal/ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#3B3A3A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#01B1FD";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }
    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "churchName") {
      setChurchNameError(false);
    }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    navigate("/assessment-additional/" + id, { replace: true });
  };

  if (isLoading) {
		return <div className="container details loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        {/* <h1>You're all done!</h1> */}
        <div style={{ height: '10px' }}></div>
        <p>Before we get to your report, please confirm<br/>your details so that we can email you a copy.</p>

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="simon@rockchurch.com" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Job title<span className="required-field">*</span></p>
            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle} 
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#01B1FD33',
                  // primary: '#927AA0',
                  primary: '#01B1FD',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("jobTitle")}
          </div>

          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Ministry name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={churchName} onFocus={(e) => removeHintText("churchName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          }
          
          {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Postal or ZIP code<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="11618" 
                  className="form-details-text" 
                  value={zipCode} 
                  onFocus={(e) => removeHintText("zipCode", e)} 
                  onChange={(e) => {updateZipCode(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          }


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
              </Button>
            </div>

            <div className="cta-btn">
              <Button onClick={handleSubmit} disabled={isSaving}>
                {showButton()}
              </Button>
            </div>
          </div>
        

      </div>
      
    </div>
  );
  
}
export default Details;