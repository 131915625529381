import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import Question from '../Components/Questions';
import ProgressBar from '../Components/ProgressBar';
// import ProgressBarSingle from '../Components/ProgressBarSingle';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import '../App.scss';

const Assessment = () => {

  let navigate        = useNavigate();
  const { id }        = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#FBF9F2";
    
    setIsLoading(true);
    // setQs(questions);
    if(id != null) {      
      getAssessment();      
    } else {
      getQuestions();
    }

  }, []);

  const [searchParams, setSearchParams]                 = useSearchParams();
  const [isLoading, setIsLoading]                       = useState(true);
  const [activeStep, setActiveStep]                     = useState(0);
  // const [prevValue, setPrevValue]                       = useState(0);
  // const [nextValue, setNextValue]                       = useState(0);
  const [sections, setSections]                         = useState([]);
  const [questionsFromApi, setQuestionsFromApi]         = useState([]);
  const [questions, setQuestions]                       = useState([]);
  const [prevValue, setPrevValue]                       = useState(questions[questions.length - 1]?.answer);
  const [questionCount, setQuestionCount]               = useState(0);
  const [currentHash, setCurrentHash]                   = useState(0);
  
  // const [nextDisabled, setNextDisabled] = React.useState(true);

  function getAssessment() {
    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
				setSections(data.sections);
				setQuestions(data.questions);
        setQuestionCount(data.questions.length);
        setIsLoading(false);
        setActiveStep(data.questions.length - 1);
        setPrevValue(data.questions[data.questions.length - 1].answer);
        setCurrentHash(id);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  // change all default unanswered questions 'answer' value to null instead of 0 
  useEffect(() => {
    // setQuestions((prevQues) => prevQues.map(q => ({...q, answer: null})))
    setQuestions(questionsFromApi?.map(q => ({...q, answer: null})));
  }, [questionsFromApi])
  console.log(questions);

  function getQuestions() {

    let clientId              = process.env.REACT_APP_CLIENT_ID;
    
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
      console.log(data);
			if(data.status == "success") {
        console.log(data);
				setSections(data.sections);
				// setQuestions(data.questions);
        setQuestionsFromApi(data.questions);
        setQuestionCount(data.questions.length);
        setIsLoading(false);
			} else {
				// setOpenSnackbar(true);
			}
		});

  }

  function completeAssessment() {

    setIsLoading(true);

    let apicall               = '/v1/assessment';
    if(searchParams.get("leader") != null) {
      apicall                 = '/v1/assessment/' + searchParams.get("leader") + '/member/' + searchParams.get("user");
    }

    let isReview              = false;
    if(id != null) {      
      isReview                = true;
    }

    let clientId              = process.env.REACT_APP_CLIENT_ID;

		fetch(process.env.REACT_APP_API_URI + apicall, {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
        clientId,
				questions,
        isReview,
        id
			})
		}).then(response => {
			return response.json();
		}).then(data => {
			if(data.status == "success") {
        if(data.member == true) {
          navigate("/assessment-additional/" + data.id + '?member=' + data.memberId, { replace: true });
        } else {
          navigate("/assessment-additional/" + data.id, { replace: true });
        }
			} else {
				// setOpenSnackbar(true);
			}
		});

	}

  const prevQuestion = () => {
    if(activeStep < 1) {
      navigate("/start", { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answer);
    }
  };

  const nextQuestion = () => {
    if(activeStep < questions.length - 1) {
      setTimeout(() => {
        setActiveStep(activeStep + 1);
        setPrevValue(questions[activeStep + 1].answer);
      }, 20);
    } else if(currentHash != 0) {
      navigate("/assessment-additional/" + currentHash, { replace: true });
    }
  };

  const moveToNextQuestion = (answer) => {
    if(activeStep < questions.length - 1) {
      questions[activeStep].answer = answer;
      setTimeout(() => {setActiveStep(activeStep + 1);}, 500);
    } else {
      questions[activeStep].answer = answer;
      setTimeout(() => { completeAssessment(); }, 500);
    }
  }

  // const nextDisabled = () => {
  //   if(questions[activeStep].answer > 0) {
  //     return {display: "block"};
  //   };
  //   return {display: "none"};
  // }
  const nextDisabled = (answer) => {
    console.log("questions[activeStep].answer", questions[activeStep].answer);
    console.log("questions[activeStep]", questions[activeStep]);
    if(questions[activeStep].mandatory == "no") {
      return {display: "block"};
    } else {
      // 0 is a possible answer value after scoring change where possible values are -10, -5, 0, 5, 10 so disabled this change for 0
      // if(questions[activeStep].type == "slider" && questions[activeStep].answer != 0) {
      if(questions[activeStep].type == "slider" && questions[activeStep].answer !== null) {
        return {display: "block"};
      } else if(questions[activeStep].type == "radio" && questions[activeStep].answer != 0) {
        return {display: "block"};
      } else if(questions[activeStep].type == "textarea" && questions[activeStep].answerTxt != "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  const getCurrentSection = () => {
    // console.log(activeStep);
    // console.log(questions[activeStep]?.sectionId);
    let questionsSectionID = questions[activeStep]?.sectionId;
    let currentSection = 0;
    sections.find((section, index) => {
      if(section.id === questionsSectionID){
        currentSection = index+1;
      } 
      return currentSection;
      });
    console.log("currentSection", currentSection);
    return currentSection;
  }
  
  const getCurrentSectionName = () => {
    let questionsSectionID = questions[activeStep]?.sectionId;
    let currentSectionName = "";
    sections.find((section, index) => {
      if(section.id === questionsSectionID){
        currentSectionName = section.section;  
      } 
      return currentSectionName;
      });
    console.log("currentSectionName", currentSectionName);
    return currentSectionName;
  }
  // console.log(getCurrentSectionName());
  

  const getStepContent = (step) => {
    return (
      <Question question={questions[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
    );
  };

  if (isLoading) {
		return <div className="container assessment loading"><CircularProgress  style={{'color': '#F7D54D'}} size={60} /></div>
	}

  return (
    <div className="container assessment">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">

              <div className="spacer"></div>

              <ProgressBar currentProgress={activeStep} currentSectionName={getCurrentSectionName()} />
              {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} /> */}
              <div className="spacer"></div>
              {/* <div className="spacer"></div> */}
              {/* <div className="spacer"></div> */}
              <div className="spacer"></div>

              <div className="current-section">
                <p className="current-section-num">{activeStep + 1}</p>
              </div>

              
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div> */}

              {getStepContent(activeStep)}

              <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;PREV
                </Button>
                <Button onClick={nextQuestion} style={nextDisabled()}>
                  NEXT&nbsp;&nbsp;<img src={nextArrow} />
                </Button>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
export default Assessment;