import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as highchartsMore from "highcharts/highcharts-more";
import bookImg from "../Assets/Images/ic-book.png"
import amazon from "../Assets/Images/amazon.png"
import audible from "../Assets/Images/audible.png"
import lifeway from "../Assets/Images/lifeway.png"
import barnesNoble from "../Assets/Images/barnes&noble.png"
import moody from "../Assets/Images/moody.png"
import target from "../Assets/Images/target.png"


// import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";

// import prevArrow from "../Assets/Images/prev-arrow.svg";
// import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.png";
import quotationImage from "../Assets/Images/quotation.svg";

// import analyzeIcon from '../Assets/Images/insights.svg';
// import alignmentIcon from '../Assets/Images/align.svg';
// import advanceIcon from '../Assets/Images/advance.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";

const labelsAndInfo = [
  { id: "CURIOSITY", 
    def: "Curiosity is the desire to know something.",
    support: [
      "Curiosity may kill cats, but it also gives birth to innovation! Innovators are curious learners.", 
      "Curiosity drives learning. In 2 Timothy 2:13, Paul asks for the books and parchments to be brought to him.", 
      "This is remarkable, because Paul had many years of ministry experience, including direct contact with Jesus. Yet, even though he is approaching his last years, Paul is still reading and learning. He is an example of a lifelong learner."],
    improve: ["To grow in the area of curiosity, develop the habits of a life-long learner. Take on a new hobby, read a book outside of your normal area of interest, or learn to ask probing questions of new people you meet."],
  },
  { id: "COLLABORATION", 
    def: "Collaboration is your willingness to work with others to solve problems.",
    support: [
      "Collaboration and innovation are close partners. The extent to which you can work with others is important.", 
      "Some ministry challenges can only be solved through collaboration.", 
      "How much do you value working with others? How much are you willing to compromise as you seek your objectives? How well do you listen when others are offering contrary opinions? These are questions you can think about to improve your ability to collaborate."],
    improve: ["To improve your collaboration quotient, consider a problem which is too big for you to solve alone. Then, initiate a discussion on that problem with those that you need to create a solution."],
  },
  { id: "FLEXIBILITY", 
    def: "Flexibility is how willing you are to adjust to new ideas, people, and experiences.",
    support: [
      "Many of the attributes here, from collaboration to initiative to risk taking, require flexibility.", 
      "However, innovation will not happen if our thinking process is rigid. Flexible people avoid letting these categories become their reality. They can shift their thinking when necessary.", 
      "Subjecting yourself to environments which cause you to see the world through different worldviews and require flexibility can increase your aptitude in this attribute."],
    improve: ["To improve in flexibility, practice by exposing yourself to a change. Place yourself into situations where you don't control the schedule and stretch yourself by considering contrarian viewpoints."],
  },
  { id: "CREATIVITY", 
    def: "Creativity is the ability to generate new concepts, mix existing concepts into something new, and challenge conventions.",
    support: [
      "When we create, we are mimicking our Creator. As He created us, part of the image of God in us is this same drive to create.", 
      "Creativity and the arts are inextricably linked. Culture is influenced by the arts.", 
      "However, ministry creativity includes a broader view which considers many different forms of creation such as \"organizational artists\", who influence more than we realize."],
    improve: ["To grow in creativity, think about something you currently do in your ministry or work that is familiar to you. Create a list of ways that this same thing could be accomplished that is different from the current way."],
  },
  { id: "RISK-TAKING", 
    def: "Risk-Taking is anything that you do which may fail or involves danger.",
    support: [
      "Everybody has a different tolerance level for risk. To be innovative means that some level of risk-taking must occur.", 
      "Finding the appropriate balance between “duty of care” and risk-taking is a challenge in our safety-oriented world. But, as Christians, risk is inherent to our identity and way of life.", 
      "In our day and age, it is risky to say that without Christ, people are condemned. Yet, that is part of the message our churches are tasked with making. Ministry means taking risks to share the kingdom with others."],
    improve: ["Look at your list of current projects and ask yourself how many of them might not happen. If there are no projects with the possibility of failure, you may have a risk-averse project list. Consider a project which may or may not work out."],
  },
  { id: "INITIATIVE", 
    def: "Initiative is a preference to action instead of allowing the status quo to continue.",
    support: [
      "Initiative is the willingness to forego one opportunity for another. Somebody who takes initiative leaps toward the new opportunity and leaves behind what was there before.", 
      "It can be hard for people and ministries to lay aside the familiar, but understanding the early signs of change can help ministries transform the leap toward something new into a jump."],
    improve: ["To grow in the area of initiative, try creating a list of projects that need innovation. Then, ask yourself, “What is the next action to take on this project?” Pick one and implement that action!"],
  },
];

const Results = (props) => {

  const { windowWidth }                 = useWindowDimensions();
  let navigate                          = useNavigate();
  const { id }                          = useParams();
  
  const [isLoading, setIsLoading]       = useState(true);
  const [isMember, setIsMember]         = useState(false);
  const [name, setName]                 = useState("");
  const [score, setScore]               = useState(0);
  const [subScore1, setSubScore1]       = useState(0);
  const [subScore2, setSubScore2]       = useState(0);
  const [subScore3, setSubScore3]       = useState(0);
  const [subScore4, setSubScore4]       = useState(0);
  const [subScore5, setSubScore5]       = useState(0);
  const [subScore6, setSubScore6]       = useState(0);
  const [grpSubScore1, setGrpSubScore1] = useState(0);
  const [grpSubScore2, setGrpSubScore2] = useState(0);
  const [grpSubScore3, setGrpSubScore3] = useState(0);
  const [grpSubScore4, setGrpSubScore4] = useState(0);
  const [grpSubScore5, setGrpSubScore5] = useState(0);
  const [grpSubScore6, setGrpSubScore6] = useState(0);
  const [sortedLabelsAndInfo, setSortedLabelsAndInfo] = useState(labelsAndInfo.sort((a, b) => a.score - b.score));
  const [apiData, setApiData]           = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [hoveredText, setHoveredText]   = useState(sortedLabelsAndInfo[0].id);
  const [infoText, setInfoText]         = useState(labelsAndInfo.find(label => label.id === hoveredText)?.def);
  // const [infoPosition, setInfoPosition] = useState({right: "4%", left: "33.5%"});
  const [infoPosition, setInfoPosition] = useState({});
  
  // use subScores only for bar chart animations 
  const [subScores, setSubScores] = useState([subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
  const [grpSubScores, setGrpSubScores] = useState([grpSubScore1, grpSubScore2, grpSubScore3, grpSubScore4, grpSubScore5, grpSubScore6]);
  const [chartSubScores, setChartSubScores] = useState([]);
  const [chartGrpSubScores, setChartGrpSubScores] = useState([]);
  const [barChartSubScores, setBarChartSubScores] = useState([]);
  const [barChartGrpSubScores, setBarChartGrpSubScores] = useState([]);

  // console.log(subScores, grpSubScores);
  
  let chartElm = document?.getElementById("spiderweb-chart1");
  // console.log(chartElm);
  
  // set hoveredText to lowest score's text after fetching scores from API 
  let lowestScore = sortedLabelsAndInfo[0].score;
  useEffect(() => {
      setHoveredText(sortedLabelsAndInfo[0]?.id);
      // console.log("useEffect", sortedLabelsAndInfo[0].id, sortedLabelsAndInfo[0].score);
  }, [sortedLabelsAndInfo, lowestScore])
  
  useEffect(() => {
    const checkHover = (e) => {
      // console.log(e.target.innerHTML);
      if(e.target.classList.contains("custom-tooltip-text")) {
        console.log(e.target.innerHTML);
        let text = e.target.innerHTML;
        let finalText = e.target.innerHTML;
        switch (text) {
          case 'INITI-<br>ATIVE':
            finalText = "INITIATIVE";
            break;
          case 'RISK-<br>TAKING':
            finalText = "RISK-TAKING";
            break;
          case 'FLEXI-<br>BILITY':
            finalText = "FLEXIBILITY";
            break;
          case 'COLLAB-<br>ORATION':
            finalText = "COLLABORATION";
            break;
          default:
            finalText = text;
        }
        // setHoveredText(e.target?.innerHTML)
        setHoveredText(finalText);
        // console.log("infoText", infoText);
        // console.log("hoveredText", hoveredText);
      }
    }
    
    if(chartElm) {
      chartElm.addEventListener('mouseover', checkHover, false);
    }

    if(chartElm) {
      return () => {
        chartElm.removeEventListener('mouseover', checkHover);
      }
    }

  }, [chartElm, hoveredText]);

  useEffect(() => {
    setInfoText(labelsAndInfo.find((item) => item?.id === hoveredText)?.def);
  }, [hoveredText, chartElm])
  
  useEffect(() => {
    if (windowWidth >= 1100) {
      setInfoPositionStyle(labelsAndInfo.find((item) => item?.id === hoveredText)?.id);
    }
  }, [hoveredText, chartElm, chartOptions, windowWidth])


  // console.log("infoText", infoText);
  // console.log("hoveredText", hoveredText);

  highchartsMore(Highcharts);
  
  const setInfoPositionStyle = (section) => {
    // console.log(section);
    if(section === "RISK-TAKING") {
      setInfoPosition({right: "4%", top: "75%"});
    } else if(section === "CREATIVITY") {
      // if score highest/lowest, show banner lower to make space for highest/lowest score label
      if(sortedLabelsAndInfo[0]?.id === section || sortedLabelsAndInfo[sortedLabelsAndInfo?.length - 1]?.id === section) {
        setInfoPosition({right: "34.5%", top: "97.5%"});
      } else setInfoPosition({right: "34.5%", top: "94.5%"});
    } else if(section === "FLEXIBILITY") {
      setInfoPosition({right: "64%", top: "75%"});
    } else if(section === "COLLABORATION") {
      setInfoPosition({right: "65%", top: "34.5%"});
    } else if(section === "CURIOSITY") {
      // if score highest/lowest, show banner higher to make space for highest/lowest score label
      if(sortedLabelsAndInfo[0]?.id === section || sortedLabelsAndInfo[sortedLabelsAndInfo?.length - 1]?.id === section) {
        setInfoPosition({right: "34.5%", top: "-13.5%"});
      } else setInfoPosition({right: "34.5%", top: "-11%"});
    } else {
      setInfoPosition({right: "4%", top: "34.5%"});
    }
  }
  
  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.status === "success") {
        console.log(data);
        setIsLoading(false);
        setApiData(data);
        setName(data.name);
        setScore(data.score);
        setSubScore1(data.subScore1);
        setSubScore2(data.subScore2);
        setSubScore3(data.subScore3);
        setSubScore4(data.subScore4);
        setSubScore5(data.subScore5);
        setSubScore6(data.subScore6);
        setGrpSubScore1(data.groupSubScore1)
        setGrpSubScore2(data.groupSubScore2)
        setGrpSubScore3(data.groupSubScore3)
        setGrpSubScore4(data.groupSubScore4)
        setGrpSubScore5(data.groupSubScore5)
        setGrpSubScore6(data.groupSubScore6)
        setSubScores([data.subScore1, data.subScore2, data.subScore3, data.subScore4, data.subScore5, data.subScore6]);
        setGrpSubScores([data.groupSubScore1, data.groupSubScore2, data.groupSubScore3, data.groupSubScore4, data.groupSubScore5, data.groupSubScore6]);
        setIsMember(data.isTeamMember);
      } else {
        // setOpenSnackbar(true);
      }
    });
  };
  
  useEffect(() => {
    fetchResults();
  }, []);

  useEffect(() => {
    const matchLabelWithScore = (item) => {
      
      if(item.id === "CURIOSITY"){
        return [subScore1, grpSubScore1];
      } else if (item.id === "INITIATIVE") {
        return [subScore2, grpSubScore2];
      } else if (item.id === "RISK-TAKING") {
        return [subScore3, grpSubScore3];
      } else if (item.id === "CREATIVITY") {
        return [subScore4, grpSubScore4];
      } else if (item.id === "FLEXIBILITY") {
        return [subScore5, grpSubScore5];
      } else if (item.id === "COLLABORATION") {
        return [subScore6, grpSubScore6];
      }
    }
    labelsAndInfo.forEach(label => {
      label.score = matchLabelWithScore(label)[0];
      label.avgScore = matchLabelWithScore(label)[1];
    });

  }, [grpSubScore1, grpSubScore2, grpSubScore3, grpSubScore4, grpSubScore5, grpSubScore6, subScore1, subScore2, subScore3, subScore4, subScore5, subScore6]);
  
  
  useEffect(() => {
    // change negative scores between -30 and +30 to positive scores between 0 and 60 for chart by adding 30 to each score. so new min and max will be 0 and 60 
    setChartSubScores(subScores?.map(score => (score + 50)));
    setChartGrpSubScores(grpSubScores?.map(score => (score + 50)));

    setBarChartSubScores(subScores?.map((score) => Math.round(((score + 50) / 100) * 100)));
    setBarChartGrpSubScores(grpSubScores?.map(score => Math.round(((score + 50) / 100) * 100)));

  }, [subScores, grpSubScores]);

  // console.log("subScores", subScores);
  // console.log("grpSubScores", grpSubScores);
  // console.log("spiderChartSubScores", chartSubScores);
  // console.log("spiderChartGrpSubScores", chartGrpSubScores);
  // console.log("barChartSubScores", barChartSubScores);
  // console.log("barChartGrpSubScores", barChartGrpSubScores);

  useEffect(() => {
    let chartData = {
      chart: {
        // plotBackgroundColor: '#5d557d',
        // backgroundColor: '#5d557d',
        // marginBottom: 100,
        plotShadow: false,
        polar: true,
        type: 'area',
        height: 450,
        // width: 750,
        tickInterval: 1,
        startOnTick: true,
        endOnTick: false,
        // margin: 'auto',
        style: {
          fontFamily: "Lato",
        },
      },
      colors: ['#927AA0', '#45A5CF', '#8bbc21', '#2f7ed8', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
   
      credits: {
        enabled: false,
      },
      
      accessibility: {
          description: 'A spiderweb chart compares your scores against the average scores. The spider chart has six spokes. Each spoke represents one of the 6 sections: COLLABORATION, CREATIVITY, CURIOSITY, FLEXIBILITY, INITIATIVE, and RISK-TAKING. The chart is interactive, and each data point is displayed upon hovering.'
      },
      
      title: {
          text: 'Your Scores vs Average Scores',
          x: -80
      },
      
      pane: {
          size: '80%'
      },
      
      xAxis: {
        categories: [
          'CURIOSITY', 
          'INITIATIVE',
          'RISK-TAKING', 
          'CREATIVITY', 
          'FLEXIBILITY', 
          'COLLABORATION', 
        ],
        tickmarkPlacement: 'on',
        tickInterval: 1,
        startOnTick: true,
        endOnTick: false,
        tickColor: 'transparent',
        lineWidth: 0,
        labels: {
          enabled: true,
          useHTML: true,
          formatter: function() {
            // console.log(this.value);
            // text = (text.length < 6) ? this.value : this.value.substring(0, 6) + '-'
            let text = this.value;
            let finalText = this.value;
            if(windowWidth < 651) {
              switch (text) {
                case 'CURIOSITY':
                  finalText = text;
                  break;
                case 'INITIATIVE':
                  // return "INITI-"+'<br/>'+"ATIVE";
                  finalText = "INITI-<br>ATIVE";
                  break;
                case 'RISK-TAKING':
                  finalText = "RISK-<br>TAKING";
                  break;
                case 'CREATIVITY':
                  finalText = text;
                  break;
                case 'FLEXIBILITY':
                  finalText = "FLEXI-<br>BILITY";
                  break;
                case 'COLLABORATION':
                  finalText = "COLLAB-<br>ORATION";
                  break;
                default:
                  finalText = text;
              }
              return (
                '<div class="custom-tooltip"><span class="highest-score">Highest<br/>score</span><span class="lowest-score">Lowest<br/>score</span><p class="custom-tooltip-text">' + finalText + '</p></div>')
            } else return (
            '<div class="custom-tooltip"><span class="highest-score">Highest score</span><span class="lowest-score">Lowest score</span><p class="custom-tooltip-text">' + finalText + '</p></div>')
          },
          style: {
            fontSize: "16px",
            fontWeight: 700,
            color: "black",
          },
        },
        gridLineColor: '#9d9d9d9d',
      },
            
      yAxis: {
        // categories: ['', '', '', '', ''],
        tickInterval: 1,
        startOnTick: true,
        endOnTick: true,
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        gridLineColor: '#9d9d9d9d',
        max: 10,
        tickColor: 'transparent',
      },
      plotOptions: {
        area: {
          // fillColor: '#927AA0',
          opacity: 0.9,
        },    
      },
      
      tooltip: {
          enabled: true,
          padding: 0,
          distance: 0,
          animation: false,
          // shared: true,
          // pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y:,.0f}</b><br/>'
          useHTML: true,
          formatter: function() {
            // console.log("x: ", this.x, "y: ", this.y);
            setHoveredText(this.x);
            return '<span style="display: none; color: transparent; background: transparent;" class="hidden-tooltips">'+ this.x +'</span>';
        }
      },
      
      legend: {
        enabled: false,
        // layout: 'horizontal',
        // floating: true,
        // align: 'center',
        // verticalAlign: 'bottom',
      },
      
      series: [
        {
          name: 'Average Scores',
          data: [
            Math.round(chartGrpSubScores[0]/10),
            Math.round(chartGrpSubScores[1]/10),
            Math.round(chartGrpSubScores[2]/10),
            Math.round(chartGrpSubScores[3]/10),
            Math.round(chartGrpSubScores[4]/10),
            Math.round(chartGrpSubScores[5]/10),
          ],
          pointPlacement: 'on'
        },
        {
          name: 'Your Scores',
          data: [
            // divide scores by 10 to get chart mapping values from 0 to 6 
            Math.round(chartSubScores[0]/10),
            Math.round(chartSubScores[1]/10),
            Math.round(chartSubScores[2]/10),
            Math.round(chartSubScores[3]/10),
            Math.round(chartSubScores[4]/10),
            Math.round(chartSubScores[5]/10),
          ],
          pointPlacement: 'on'
        }
      ],
      
      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal'
                  },
                  pane: {
                      size: '70%'
                  }
              }
          }]
        }
    };
    setChartOptions(chartData);
  }, [chartSubScores, chartGrpSubScores, windowWidth]);
  
  // console.log(labelsAndInfo);
  // const sortedLabelsAndInfo = labelsAndInfo.sort((a, b) => a.score - b.score);
  // console.log("sortedLabelsAndInfo", sortedLabelsAndInfo);

  useEffect(() => {
    let labelsArr = document.querySelectorAll(".custom-tooltip");
    // console.log(labelsArr);
    for (let i = 0; i < labelsArr.length; i++) {
      // check lowest score and show that label 

      let text = labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].innerHTML;
        let finalText = labelsArr[i].getElementsByClassName("custom-tooltip-text")[0].innerHTML;
        switch (text) {
          case 'INITI-<br>ATIVE':
            finalText = "INITIATIVE";
            break;
          case 'RISK-<br>TAKING':
            finalText = "RISK-TAKING";
            break;
          case 'FLEXI-<br>BILITY':
            finalText = "FLEXIBILITY";
            break;
          case 'COLLAB-<br>ORATION':
            finalText = "COLLABORATION";
            break;
          default:
            finalText = text;
        }

      // check lowest score and show that label 
      if(finalText === sortedLabelsAndInfo[0]?.id) {
        labelsArr[i].getElementsByClassName("lowest-score")[0].classList.add('show');
      } else {
        labelsArr[i].getElementsByClassName("lowest-score")[0].classList.add('hide');
      }
      
      // check highest score and show that label 
      if(finalText === sortedLabelsAndInfo[sortedLabelsAndInfo.length-1]?.id) {
        labelsArr[i].getElementsByClassName("highest-score")[0].classList.add('show');
      } else {
        labelsArr[i].getElementsByClassName("highest-score")[0].classList.add('hide');
      }

      // for Creativity which is at the bottom of chart. To prevent label from overlapping on chart 
      if(finalText === "CREATIVITY") {
        labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "-15px";
        labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "-15px";
      }
      if(windowWidth < 651) {
        if(finalText === "CURIOSITY") {
          labelsArr[i].getElementsByClassName("lowest-score")[0].innerHTML = "Lowest score";
          labelsArr[i].getElementsByClassName("highest-score")[0].innerHTML = "Highest score";
          labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "20px";
          labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "20px";
        }
        if(finalText === "CREATIVITY") {
          labelsArr[i].getElementsByClassName("lowest-score")[0].innerHTML = "Lowest score";
          labelsArr[i].getElementsByClassName("highest-score")[0].innerHTML = "Highest score";
          labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "-15px";
          labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "-15px";
        }
        if(finalText === "FLEXIBILITY" || finalText === "RISK-TAKING") {
          labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "-35px";
          labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "-35px";
        }
      }
      if(windowWidth < 551) {
        if (finalText === "FLEXIBILITY" || finalText === "RISK-TAKING") {
          labelsArr[i].getElementsByClassName("lowest-score")[0].style.bottom = "-32px";
          labelsArr[i].getElementsByClassName("highest-score")[0].style.bottom = "-32px";
        }
      } 
    }

  }, [chartOptions, sortedLabelsAndInfo, windowWidth]);

  // const quotientVal = 3;
  const showTitleText = () => {
    // if (score > 95) {
    //   return <h1>{name}, your church is extremely healthy</h1>
    // } else if (score > 80) {
    //   return <h1>{name}, your church can become even healthier</h1>
    // } else if (score > 70) {
    //   return <h1>{name}, your church needs to work toward getting healthier</h1>
    // } else {
    //   return <h1>{name}, your church is beset by unhealthiness</h1>
    // }
    return (<h1>{name}, there's room for innovating in the area of <span className="lowest-score-name">"{sortedLabelsAndInfo[0]?.id?.toLowerCase()}"</span>!</h1>);
  };

  // const showScoreText = () => {
  //   if (score > 95) {
  //     return <div className="result-text">

  //       <p>Congratulations! It looks like your church is displaying clear signs of moving toward great health.</p>
  //       <p>We estimate that less than 1 percent of churches will score this highly, So, we encourage your church to continue what it is presently doing to ensure it stays effective and healthy.</p>
  //     </div>
  //   } else if (score > 80) {
  //     return <div className="result-text">

  //       <p>It's great that you are already working on key issues but your church can surely do more and get healthier.</p>
  //       <p>Your church has significant potential to move to the healthiest level. So, we encourage your leadership to look for potential areas of growth and see what remedies are possible to ensure your church becomes even healthier.</p>
  //     </div>
  //   } else if (score > 70) {
  //     return <div className="result-text">

  //       <p>Based on your current score, it looks like your church needs to work on becoming healthier.</p>
  //       <p>Often, churches at this level might need significant work to move into the healthy category and I hope this assessment has revealed some clear starting points.</p>
  //       <p>We encourage your church to give special attention to the areas you scored lower in, so you can begin to grow toward greater church health.</p>
  //     </div>
  //   } else {
  //     return <div className="result-text">
 
  //       <p>Based on your current score, it looks like your church is currently battling unhealthiness.</p>
  //       <p>While the task might seem hard and progress might appear minimal, please remember that significant growth or a meaningful ministry might be just around the corner.</p>
  //       <p>By focusing and working on all the critical areas revealed in this assessment, you can easily begin the journey to improving your church's health!</p>
  //     </div>
  //   }
  // };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this test</h3>
        <p>Invite your team member or a friend to take the test and help them discover where they are on the journey to creating a disruptive influence in ministry!</p>

        {/* <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div> */}

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking the test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          Schedule a call with Carey to debrief the results of your test and
          ensure your church is prepared with next steps for future evangelism.
          <div className="cta-btn">
            <a
              href="https://calendly.com/kurianbk/hear-the-bleat-story"
              target="_blank"
            >
              <Button>TALK WITH CAREY</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <p>Invite other church leaders to take this test and help them discover their unique innovation quotient!</p>
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE YOUR TEAM</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F7D54D" }} size={60} />
      </div>
    );
  }

  // console.log(labelsAndInfo);
  const scoreText = () => {
    let score = labelsAndInfo?.find(label => label.id === hoveredText)?.score;
    let finalScore = Math.round(((score + 50)/100) * 100);
    return finalScore;
  }

  const avgText = () => {
    let score = labelsAndInfo?.find(label => label.id === hoveredText)?.avgScore;
    let finalScore = Math.round(((score + 50)/100) * 100);
    return finalScore;
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}
          {/* <span className="big-innovation-quotient">{quotientVal}</span> */}
          <div className="subheader-text">
            {sortedLabelsAndInfo[0].support.map((text, index) => <p key={index} className="support-text">{text}</p>)}
            <p className="improve-text"><span>Steps for improvement: </span>{sortedLabelsAndInfo[0].improve}</p>
          </div>
          
          
          
          <h2 className="results-subheader radar-graph">RADAR GRAPH</h2>
          <p className="radar-info">Click on the categories below to see a description of each.</p>
          <div className="spiderweb-chart" id="spiderweb-chart1">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            <div className="label-info" style={infoPosition}>
              <p className="label-text" style={{ marginBottom: infoText ? "10px" : 0 }}>
              {infoText}
              </p>
              <div className="label-score">
                <div className="your-score">&nbsp;</div>Your Score <span className="score-text">{scoreText()}%</span>
                <div className="average-score">&nbsp;</div>Average Score <span className="score-text">{avgText()}%</span>
              </div>
            </div>
          </div>

          {/* <div id="chart">
            <ReactApexChart options={apexChartData.options} series={apexChartData.series} type="radar" height={350} />
          </div> */}

          <h2 className="results-subheader bar-graph">BAR GRAPH</h2>
          
          
          
          
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {/* {showScoreText()} */}
            {/* </div>
      </div> */}

          <div className="score-section-container">

            <div className="score-markers score-markers-mobile">
              <div className="your-score">&nbsp;</div>YOUR SCORE&nbsp;&nbsp;<br/>
              <div className="average-score">&nbsp;</div>AVERAGE SCORE
            </div>

            <div className="score-section">
              <p className="score-name">CURIOSITY</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[0]} /><span className="score-txt">{barChartSubScores[0]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[0]} /><span className="score-txt">{barChartGrpSubScores[0]}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">CREATIVITY</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[3]} /><span className="score-txt">{barChartSubScores[3]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[3]} /><span className="score-txt">{barChartGrpSubScores[3]}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">INITIATIVE</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[1]} /><span className="score-txt">{barChartSubScores[1]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[1]} /><span className="score-txt">{barChartGrpSubScores[1]}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">FLEXIBILITY</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[4]} /><span className="score-txt">{barChartSubScores[4]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[4]} /><span className="score-txt">{barChartGrpSubScores[4]}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">RISK-TAKING</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[2]} /><span className="score-txt">{barChartSubScores[2]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[2]} /><span className="score-txt">{barChartGrpSubScores[2]}%</span></div>
            </div>

            <div className="score-section">
              <p className="score-name">COLLABORATION</p>
              
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartSubScores[5]} /><span className="score-txt">{barChartSubScores[5]}%</span></div>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={barChartGrpSubScores[5]} /><span className="score-txt">{barChartGrpSubScores[5]}%</span></div>
            </div>

            <div className="score-markers score-markers-desktop">
              <div className="your-score">&nbsp;</div>YOUR SCORE&nbsp;&nbsp;
              <div className="average-score">&nbsp;</div>AVERAGE SCORE
            </div>

          </div>

        </div>
      </div>

      {showInvite()}

      {showTeamMember()}

      <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>The Innovation Crisis is disruptive in the best possible way, calling leaders to reawaken the creative fire that has grown cold. It is an invitation to creative possibilities, bold risks, and taking the first steps.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            <p className="person-name">Peter Greer</p>
            <p className="person-info">President & CEO, HOPE International</p>
            <p className="person-more-info">Co-author of "Mission Drift"</p>
          </div>

        </div>

      </div>

      {/* {showSchedule()} */}
      {/* {showLegacyInvite()} */}

      {/* </div> */}

      <section className="book-section">
        <div className="book-image">
          <img src={bookImg} alt="The Innovation Crisis book by Ted Esler" />
        </div>
        <div className="book-details">
          <h3>BUY THE BOOK</h3>
          <p>Take the next step and get started on the journey toward creating a disruptive influence in ministry!</p>
          <p className="available-now">Available now:</p>
          <div className="book-links">
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.amazon.com/Innovation-Crisis-Creating-Disruptive-Influence/dp/0802421806" 
            title="The Innovation Crisis book by Ted Esler - amazon.com">
              <img className="shop-logo" src={amazon} alt="The Innovation Crisis book by Ted Esler on amazon.com" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.audible.com/pd/The-Innovation-Crisis-Audiobook/B09PMKFYM9" 
            title="The Innovation Crisis audio book by Ted Esler - audible.com">
              <img className="shop-logo" src={audible} alt="The Innovation Crisis audio book by Ted Esler on audible.com" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.lifeway.com/en/product/the-innovation-crisis-P008015722" 
            title="The Innovation Crisis book by Ted Esler - lifeway.com">
              <img className="shop-logo" src={lifeway} alt="The Innovation Crisis book by Ted Esler on Lifeway" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.barnesandnoble.com/w/the-innovation-crisis-ted-esler/1138865397" 
            title="The Innovation Crisis book by Ted Esler - barnesandnoble.com">
              <img className="shop-logo" src={barnesNoble} alt="The Innovation Crisis book by Ted Esler on barnes & noble" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.moodypublishers.com/the-innovation-crisis/" 
            title="The Innovation Crisis book by Ted Esler - moodypublishers.com">
              <img className="shop-logo" src={moody} alt="The Innovation Crisis book by Ted Esler on moody.com" /></a>
            
            <a className="book-link" rel="noreferrer" target="_blank" href="https://www.target.com/p/the-innovation-crisis-by-ted-esler-paperback/-/A-84117799" 
            title="The Innovation Crisis book by Ted Esler - target.com">
              <img className="shop-logo" src={target} alt="The Innovation Crisis book by Ted Esler on target.com" /></a>
              
          </div>
        </div>
      </section>

    </>

  );
};
export default Results;
