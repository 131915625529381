import React, { useRef, useEffect } from 'react';

import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumb.jpg';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import '../App.scss';

const Start = props => {

  const [isLoading, setIsLoading]         = React.useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = React.useState(false);
  const [startPlaying, setStartPlaying]   = React.useState(false);
  searchParams.get("leader");
  const vidRef = useRef(null);

  const assessmentLink = () => {
    if(searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }

  const vidsrcURL = "";

  return (
    <div className="container start">
      <div className="video">
        <video ref={vidRef} playsInline>
          <source
            src="https://40parables-assets.s3.amazonaws.com/Innovation_Crisis.mov"
            type="video/mp4"
          />
          <source
            src="https://40parables-assets.s3.amazonaws.com/Innovation-Crisis.mp4"
            type="video/mp4"
          />
        </video>
        {showThumbnail()}
        {showPlayButton()}
      </div>
      <div className="intro">
        <h1>What is your unique innovation quotient?</h1>
        <h2>
        Discover where you are on the journey toward creating a disruptive influence in ministry!
        </h2>
        <div className="completion">
          <Link to={assessmentLink()} className="cta">
            Get Started
          </Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
  );
}
export default Start;