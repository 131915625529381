import React from 'react';
import { Link } from "react-router-dom";

import bleatLogo from '../../Assets/Images/logo-bleat.svg';
// import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import innovationLogo from '../../Assets/Images/innovation-crisis-logo.png'
import innovationLogoSVG from '../../Assets/Images/innovation-crisis-logo.svg'
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header>

        <div className="assessment-title desktop" id="assessment-title"><img src={innovationLogoSVG} alt="Innovation Quotient Logo" /></div>
        {/* <div className="assessment-title mobile" id="assessment-title"><img src={innovationLogo} alt="Logo" /></div> */}

        { windowWidth > 830 &&
          <div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="25px" width="auto" /></div>
        }

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}
        
      </header>
    </>
  )
}

export default Header;
