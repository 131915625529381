import React, { useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

class QuestionsTemp extends React.Component {

  // const [showSlider, setShowSlider] = useState(false);
  // const [progress, setProgress] = useState(props.currentValue);

  constructor(props) {
    super(props);

    let sliderWidth       = 86;
    if(this.props.question?.answers?.length === 2) {
      sliderWidth         = 36;
    }
    
    this.state = {
      showSlider: false,
      progress: -11,
      sliderWidth: sliderWidth,
      maxWidth: this.props.question?.answers?.length
    }
    
    // if(props.currentValue > -11 && props.currentValue < 11) {
    //   this.state.progress   = props.currentValue;
    // } 
    
    // else if(this.props.question?.answer !== 0) {
    //   this.state.progress   = this.props.question?.answer;
    //   this.state.showSlider = true;
    // } 

    // else {
    //   this.state.showSlider = false;
    // }
    console.log(props);
  };
  
  

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    console.log(this.props.question?.answers.map(ans => ans.desktop_text + " " + ans.value));

    if(nextProps.currentValue > -11 && nextProps.currentValue < 11) {
      // this.state.progress   = nextProps.currentValue;
      this.setState({progress: nextProps.currentValue});
      this.setState({showSlider: true});
    } 
    
    // else if(this.props.question?.answer !== 0) {
    //   this.state.progress   = this.props.question?.answer;
    //   this.state.showSlider = true;
    // } 

    else {
      // this.state.showSlider = false;
      this.setState({progress: nextProps.currentValue});
      this.setState({showSlider: false});
    }
    
    let sliderWidth       = 86;
    if(nextProps.question?.answers?.length === 2) {
      sliderWidth         = 36;
    } else if(nextProps.question?.answers?.length === 3) {
      sliderWidth         = 56;
    } else if(nextProps.question?.answers?.length === 4) {
      sliderWidth         = 76;
    }
    
    this.setState({ sliderWidth: sliderWidth });
    this.setState({ maxWidth: nextProps.question?.answers?.length });
    
    if(nextProps.currentValue !== 0) {
      this.setState({progress: nextProps.currentValue});
      this.setState({ showSlider: true });
    } else if(nextProps.currentValue === 0) {
      this.setState({progress: 0});
      this.setState({ showSlider: true });      
    }
    // if(nextProps.currentValue >= -10 && nextProps.currentValue <= 10) {
    //   this.setState({progress: nextProps.currentValue});
    //   this.setState({ showSlider: true });
    // } else if(nextProps.currentValue === 0) {
    //   this.setState({progress: 0});
    //   this.setState({ showSlider: true });      
    // }
    
  }

  getLeft = (index) => {
    if(this.props.question?.answers?.length === 2) {
      return index * 80;
    } else if(this.props.question?.answers?.length === 3) {
      return index * 40;
    } else {
      return index * 20;
    }
  }

  getRadioWidth = (answers) => {
    if(answers === 3) {
      return {width: "70%", marginLeft: "7.5%"};
    }
    return {width: "50%"};
  }
  
  updateSliderTextPosition = (progress) => {
    console.log(progress);
    let activeSlider = null;
    let sliderText = this.props?.question?.answers?.find(ans => ans.value === progress)?.desktop_text;
    switch (sliderText) {
      case "Strongly Agree":
        activeSlider = 5;
        break;
      case "Agree":
        activeSlider = 4;
        break;
      case "Neutral":
        activeSlider = 3;
        break;
      case "Disagree":
        activeSlider = 2;
        break;
      case "Strongly Disagree":
        activeSlider = 1;
        break;
      default: 
        activeSlider = null;
    }
    return activeSlider;
  }

  updateStep = (progress) => {

    console.log("progress", progress);
    
    let slider    = 0;
    if(this.props.question?.answers[0].value > 0) {
      console.log("This is low");
      console.log("Progress" + progress);
      // slider    = progress * -1;
      slider    = progress;
    } else {
      progress  = progress;
      slider    = progress;
    }
    console.log("Slider: " + slider);
    console.log("Answer: " + progress);

    this.setState({showSlider: true});
    setTimeout(() => {this.setState({progress: slider})}, 0);
    this.props.questionAnswered(progress);
    setTimeout(() => {
      this.setState({showSlider: false});
      this.setState({progress: -11});
    }, 500);

  }

  updateRadioStep = (progress) => {

    setTimeout(() => {this.setState({progress: progress})}, 0);
    this.props.questionAnswered(progress);
    setTimeout(() => {
      this.setState({progress: -11});
    }, 500);

  }

  getLabelStyle = () => {
    if(window.innerWidth > 650) {

      if(this.props.question?.answers?.length === 5) {
        return { width: "100%", marginLeft: "-50%" };
      } else if(this.props.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question?.answers?.length === 3) {
        return { width: "200%", marginLeft: "-100%" };
      } else if(this.props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }

    } else {

      if(this.props.question?.answers?.length === 5) {
        return { width: "120%", marginLeft: "-60%" };
      } else if(this.props.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(this.props.question?.answers?.length === 3) {
        return { width: "300%", marginLeft: "-150%" };
      } else if(this.props.question?.answers?.length === 2) {
        return { width: "500%", marginLeft: "-250%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }

    }
    
  }

  checkOpacity = (index) => {
    if(index === 0 && this.state.showSlider) {
      return "0";
    } else {
      return "1";
    }
  }

  checkSelected = (value) => {
    if(this.state.progress === value) {
      return "label active";
    }
    return "label";
  }

  updateQuestion = (event, step) => {
    this.props.question.answerTxt = event.target.value;
    this.setState({answerTxt: event.target.value});
    this.props.textAnswer(event.target.value);
    // return true;
  }

  render() {
    return (
      <div>
              
        <div className="question">
          <p dangerouslySetInnerHTML={{__html: this.props.question?.question }}></p>
        </div>

        {
          (() => {
            switch(this.props.question?.type) {
              case "textarea":
                return <>
                  <div className="textarea-field">
                    <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={this.props.question?.answerTxt} onChange={(e) => {this.updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                  </div>
                </>
                break;
              case "radio":
                return <>
                  <div className="radio-container">
                    <div className="slider-labels" style={this.getRadioWidth(this.props.question?.answers?.length)}>
                      { 
                        this.props.question?.answers.map((data, index) => (
                          <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateRadioStep(data.value)}>
                          {/* <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}> */}
                            {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                            <Radio checked={data.value === this.state.progress} value={data.value} />
                            <p className="desktop" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                            <p className="mobile" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                          </div>
                        ))
                      }
                          {/* { 
                            this.props.question?.answers.map((data, index) => (
                              <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => this.updateStep(data.value)} />
                              // <a className="point" key={`points-${index}`} style={{ left: this.getLeft(index) + '%', opacity: this.checkOpacity(index) }}></a>
                            ))
                          } */}
                      
                    </div>
                  </div>
                </>
                break;
              default: 
                return <div className="slider-container" style={{ width: this.state.sliderWidth + '%' }}>
                  <div className="slider">
                    { this.state.showSlider ? <Slider
                      key={this.props.question?.id}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={5}
                      value={this.state.progress}
                      // value={this.updateSliderTextPosition(this.state.progress)}
                      className="custom-slider"
                    /> : <div className="slider-none-adjust"></div> }
                    <div className="slider-points">
                    { 
                      this.props.question.answers.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: this.getLeft(index) + '%', opacity: this.checkOpacity(index) }} onClick={() => this.updateStep(data.value)}></a>
                      ))
                    }
                    </div>
                    <LinearProgress className="custom-bar" variant="determinate" value={0} />
                    
                  </div>
                  <div className="slider-labels">
                    { 
                      this.props.question?.answers?.map((data, index) => (
                        <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}>
                          <p className="desktop" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                          <p className="mobile" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                        </div>
                      ))
                    }
                  </div>
                </div>
                break;
            }
          }).call(this)
        }
  
        {/* <div className="slider-container" style={{ width: this.state.sliderWidth + '%' }}>
          <div className="slider">
            { this.state.showSlider ? <Slider
              key={this.props.question.id}
              valueLabelDisplay="off"
              step={1}
              min={1}
              max={this.state.maxWidth}
              value={this.state.progress}
              className="custom-slider"
            /> : <div className="slider-none-adjust"></div> }
            <div className="slider-points">
            {/* { !this.state.showSlider ? 
              <a className="point" style={{ left: '0%'}} onClick={() => this.updateStep(1)}></a> : null } */}
              {/* { 
                this.props.question?.answers.map((data, index) => (
                  <a className="point" key={`points-${index}`} style={{ left: this.getLeft(index) + '%', opacity: this.checkOpacity(index) }} onClick={() => this.updateStep(data.value)}></a>
                ))
              }
            </div>
            <LinearProgress className="custom-bar" variant="determinate" value={0} />
            
          </div>
          <div className="slider-labels">
            { 
              this.props.question?.answers.map((data, index) => (
                <div className={this.checkSelected(data.value)} key={`labels-${index}`} style={{ left: this.getLeft(index) + '%'}} onClick={() => this.updateStep(data.value)}>
                  <p className="desktop" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                  <p className="mobile" style={this.getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                </div>
              ))
            }
          </div>
        </div> */}
  
      </div>
    );
  }
  
}
export default QuestionsTemp;