import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';

const UrgencyScore = ({ urgencyComponentProps }) => {

  const {
    urgencyScore, 
    urgencyPercent, 
    urgencyVote1, 
    urgencyVote2, 
    urgencyVote3, 
    urgencyVote4, 
    urgencyVote5
  } = urgencyComponentProps;

  const markerPercentPosition = () => {
    if (urgencyScore-10 < 0) {
      return 0;
    } else return ((urgencyScore-10)/40) * 100;
  }

  return (
    <div className="urgency">
							<h2>Team Average Urgency Score</h2>
							<div className="urgency-slider">
								<h3>Participant votes</h3>
								<Slider
									defaultValue={urgencyScore}
									valueLabelDisplay="off"
									step={40}
									min={10}
									max={50}
									value={urgencyScore}
									className="custom-slider"
								/>
								<div className="slider-points">
	
                <div className="score" style={{ left: markerPercentPosition() + '%' }}>{(urgencyScore / 10).toFixed(1)}</div>
									{/* 78.5 */}
									<div className="top-points" style={{ left: '0%' }}>{urgencyVote1}</div>
                  <div className="top-points" style={{ left: '25%' }}>{urgencyVote2}</div>
                  <div className="top-points" style={{ left: '50%' }}>{urgencyVote3}</div>
                  <div className="top-points" style={{ left: '75%' }}>{urgencyVote4}</div>
                  <div className="top-points" style={{ left: '100%' }}>{urgencyVote5}</div>

                  <div className="top-labels" style={{ left: '0%' }}></div>
                  <div className="top-labels" style={{ left: '25%' }}></div>
                  <div className="top-labels" style={{ left: '50%' }}></div>
                  <div className="top-labels" style={{ left: '75%' }}></div>
                  <div className="top-labels" style={{ left: '100%' }}></div>

                  <a className="point" style={{ left: '0%' }}></a>
                  <a className="point" style={{ left: '25%' }}></a>
                  <a className="point" style={{ left: '50%' }}></a>
                  <a className="point" style={{ left: '75%' }}></a>
                  <a className="point" style={{ left: '100%' }}></a>
	
								</div>
								<LinearProgress className="custom-bar" variant="determinate" value={0} />
								<div className="slider-labels">
                <div className="label"><p>Not<br />Urgent</p></div>
                <div className="label" style={{ left: '25%' }}><p>Hardly<br />Urgent</p></div>
                <div className="label" style={{ left: '50%' }}><p>Unsure</p></div>
                <div className="label" style={{ left: '75%' }}><p>Slightly<br />Urgent</p></div>
                <div className="label" style={{ left: '100%' }}><p>Absolutely<br />Urgent</p></div>
								</div>
							</div>
						</div>
  )
}

export default UrgencyScore;
